import { Component, Input } from '@angular/core';

import { ToastService } from '@shared/services/toast.service';
import { ResponseState } from '@shared/models/shared.model';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() set state(value: ResponseState) {
    this.currentState = value;
    if (value === ResponseState.ERROR) {
      this.displayErrorToast();
    }
  }

  @Input() errorMessage: string = '';

  public currentState: ResponseState;
  public responseState = ResponseState;

  constructor(private toastService: ToastService) { }

  private displayErrorToast() {
    this.toastService.openToast('error', this.errorMessage || 'Error_Message');
  }
}
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScrollService {
    scrollToElementID(elementID: string) {
        let position = this.getElementPosition(elementID);
        window.scrollTo({top: position , behavior: 'smooth'});
    }

    scrollToTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    getElementPosition(elementID: string, offSetTopAdjustment?: number): number {
        let offsetTop = 0;
        let element = document.getElementById(elementID);

        while (element.offsetParent) {
            offsetTop += element.offsetTop;
            element = <HTMLElement>element.offsetParent;
        }

        offsetTop -= this.getNavbarHeight();

        if (offSetTopAdjustment)
            offsetTop += offSetTopAdjustment;

        return offsetTop;
    }

    private getNavbarHeight(): number {
        let navbar = document.getElementById('navbar');

        if (navbar)
            return navbar.clientHeight;

        return 0;
    }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  private paths = {
    'base': 'http://concursultimotei.com',
    'Admin_Page': '/Admin/Login/',
    'Training_Page': '/Pregatire/',
    'Results_Page': 'rezultate/'
  }

  redirectToAdminPage() {
    location.href = this.paths['base'] + this.paths['Admin_Page'];
  }

  redirectToTrainingPage() {
    location.href = this.paths['base'] + this.paths['Training_Page'];
  }
}

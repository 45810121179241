import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@app/shared/services/api.service';
import { SettingsService } from '@app/shared/services/settings.service';
import { settingsMap } from '@app/shared/utils/constants';

import readXlsxFile from 'read-excel-file';

@Component({
  selector: 'results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.scss']
})
export class ResultsTableComponent implements OnInit {
  group: number;
  header: Array<string> = [];
  tableRows: Array<string> = [];
  settingsMap = settingsMap;
  settings = {};

  constructor(private route: ActivatedRoute, 
              private router: Router,
              private apiService: ApiService,
              private readonly settingsService: SettingsService,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.removeScrollbarStyleNode();
    this.group = parseInt(this.route.snapshot.paramMap.get('group'));
    this.readExcelData(this);
    this.settingsService.settings$.subscribe(settings => this.settings = settings);
  }

  public returnToHomePage() {
    this.router.navigate(['']);
  }

  private removeScrollbarStyleNode() {
    let scrollBarStyleNode = this.document.head.getElementsByClassName("scrollBarStyle");

    if(scrollBarStyleNode.length)
      this.document.head.removeChild(scrollBarStyleNode[0]);
  }

  private readExcelData(_this: any) {
    this.apiService.getFile('rezultate').subscribe(fileBlob => {
        let excelFile = new File(new Array<Blob>(fileBlob), "resultate.xlsx");
        readXlsxFile(excelFile, { sheet: _this.group }).then((rows) => {
          _this.header = rows[0];
          _this.tableRows = rows.splice(1);
        });
      }
    );
  }
}
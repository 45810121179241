<div class="card-container">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar>
                <icon [src]="icon" [width]="size" [height]="size"></icon>
            </div>
            <mat-card-title>{{title | transformText}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <button (click)="redirectToResultsPage()" mat-button>{{'Navbar_Item_Results' | transformText}}</button>
            <button (click)="openAnswersFile()" mat-button>{{'Results_Solutions' | transformText}}</button>
        </mat-card-content>
        <div class="overlay"></div>
    </mat-card>
</div>
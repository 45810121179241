import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { toastPosition } from '@shared/utils/constants';
import { TextService } from '@shared/services/text.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastr: ToastrService, private textService: TextService) { }

  public openToast(type: 'error' | 'success' | 'info', message: string, position: toastPosition = null) {
    message = this.textService.getText(message);
    this.toastr[type](message, '', position ? { positionClass: position } : null);
  }
}
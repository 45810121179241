import { BookVideo, TrainingTab } from "@shared/models/shared.model";

export const resourcesTabs: TrainingTab[] = [
  {
    index: 1,
    icon: 'Training_Book_Presentation_Icon',
    color: '#c2e8c2',
    title: 'Training_Book_Presentation_Title',
    component: 'BookPresentationComponent',
    path: 'prezentare',
    isCached: true
  },
  {
    index: 2,
    icon: 'Training_Questions_List_Icon',
    color: '#BED7D1',
    title: 'Training_Questions_List_Title',
    component: 'QuestionsResourcesComponent',
    path: 'intrebari',
    shouldCache: true,
    isCached: false
  },
  {
    index: 3,
    icon: 'Training_Verses_List_Icon',
    color: '#F7EBC3',
    title: 'Training_Verses_List_Title',
    component: 'VersesResourcesComponent',
    path: 'versete',
    shouldCache: true,
    isCached: false
  },
  {
    index: 4,
    icon: 'Training_Files_Icon',
    color: '#FBD6C6',
    title: 'Training_Files_Title',
    component: 'FileResourcesComponent',
    path: 'fisiere',
    shouldCache: true,
    isCached: false
  }
];

export const exercisesTabs: TrainingTab[] = [
  {
    index: 1,
    icon: 'Training_Questions_Test_Icon',
    color: '#FBD6C6',
    title: 'Training_Questions_Test_Title',
    subtitle: 'Training_Questions_Test_Subtitle',
    button: 'Training_Questions_Test_Button',
    component: 'QuestionsExerciseComponent',
    path: 'intrebari',
    showFilters: true,
    showNumbersFilter: true
  },
  {
    index: 2,
    icon: 'Training_Verses_Test_Icon',
    color: '#AFC7D0',
    title: 'Training_Verses_Test_Title',
    subtitle: 'Training_Verses_Test_Subtitle',
    button: 'Training_Verses_Test_Button',
    component: 'VersesExerciseComponent',
    path: 'versete',
    showFilters: true
  },
  {
    index: 3,
    icon: 'Training_Crosswords_Test_Icon',
    color: '#DFD8DC',
    title: 'Training_Crosswords_Test_Title',
    subtitle: 'Training_Crosswords_Test_Subtitle',
    button: 'Training_Crosswords_Test_Button',
    component: 'CrosswordComponent',
    path: 'rebus',
    showFilters: true,
  }
];

export enum TabsType {
  'resources' = 'resurse',
  'exercises' = 'exercitii'
}

export const enum toastPosition {
  topRight = 'toast-top-right',
  topLeft = 'toast-top-left',
  topCenter = 'toast-top-center',
  bottomRight = 'toast-bottom-right',
  bottomLeft = 'toast-bottom-left',
  bottomCenter = 'toast-bottom-center'
};

export const groups = [
  { value: 'Toate', text: 'Toate Grupele' },
  { value: 'Mica', text: 'Mică' },
  { value: 'Mijlocie', text: 'Mijlocie' },
  { value: 'Mare', text: 'Mare' }
];

export const books = [
  { value: 'Toate', text: 'Toate Cărțile' },
  { value: 'Proverbe_16_31', text: 'Proverbe 16-31' },
  { value: 'Matei_1_14', text: 'Matei 1-14' },
  { value: 'Petru1', text: '1 Petru' }
];

export const booksMap = {
  'Toate': 'Toate Cărțile',
  'Proverbe_16_31': 'Proverbe 16-31',
  'Matei_1_14': 'Matei 1-14',
  'Petru1': '1 Petru'
};

export const groupMap = ['Mică', 'Mijlocie', 'Mare'];

export const numbers = [
  { value: '5', text: '5 întrebări' },
  { value: '10', text: '10 întrebări' },
  { value: '15', text: '15 întrebări' },
  { value: '25', text: '25 întrebări' },
  { value: '50', text: '50 întrebări' }
];

export const fileTypes = {
  'pdf': 'application/pdf',
  'doc': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export const settingsMap = {
  'Results_Table_Contest_Year': 'An_Rezultate',
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'grid-gallery',
  templateUrl: './grid-gallery.component.html',
  styleUrls: ['./grid-gallery.component.scss']
})
export class GridGalleryComponent implements OnInit { 

  waitingCounter: number = 10;

  ngOnInit() {
    this.changeGalleryContainerArrows();
  }
  
  private changeGalleryContainerArrows() {
    let galleryArrowsContainer = document.querySelector(".pagerContainer");

    if(!galleryArrowsContainer) {
      if(this.waitingCounter > 0) {
        this.waitingCounter --;
        setTimeout(this.changeGalleryContainerArrows, 100);
      } 
    } else {
      let leftArrow = <HTMLImageElement>galleryArrowsContainer.children[0];
      let rightArrow = <HTMLImageElement>galleryArrowsContainer.children[1];

      if(leftArrow && rightArrow) {
        leftArrow.src = "assets/images/left-arrow.png";
        rightArrow.src = "assets/images/right-arrow.png";
      }
    }
  }
} 

export class ContactInfo {
    nume: string;
    prenume: string;
    email: string;
    mesaj: string;
    recaptchaToken: string;
}

export class SignUpInfo {
    nume: string;
    prenume: string;
    email: string;
    clasa: string;
    biserica: string;
    recaptchaToken: string;
}

export class GridQuestion {
    ID: number;
    Question: string;
    Book: string;
    Group: string;
    GridAnswers: GridAnswer[];
    IsCorrect: boolean;
}

export class GridAnswer {
    ID: number;
    Answer: string;
    Type: boolean;
}

export class QuizAnswerConfig {
    checked: Boolean;
}

export class TrainingFilters {
    selectedBook: string;
    selectedGroup: string;
    selectedNumber: string;
}

export interface Verse {
    ID: string;
    VerseText: string;
    VerseReference: string;
    Book: string;
    Group: string;
}

export class CrosswordConfig {
    Questions: CrosswordQuestionInfo[];
    Tip: string;
}

export class CrosswordQuestionInfo {
    ID: string;
    Question: string;
    Answer: string;
    LetterIndex: number;
}

export interface CrosswordAnswerConfig {
    isCorrect: boolean;
    showCorrectAnswer: boolean;
}

export interface TrainingTab {
    index: number;
    icon: string;
    color: string;
    component: string;
    path: string;
    title?: string;
    subtitle?: string;
    button?: string;
    showFilters?: boolean;
    showNumbersFilter?: boolean;
    shouldCache?: boolean;
    isCached?: boolean;
}

export interface BookVideo {
    name: string;
    url: string;
}

export interface FileMetadata {
    ID: number;
    Book: string;
    Group: string;
    Name: string;
}

export interface Setting {
    ID: string;
    Key: string;
    Value: string;
}

export enum ResponseState {
    LOADING = 1,
    SUCCESS = 2,
    ERROR = 3
}

export enum ViewMode {
    GRID = 'grid',
    LIST = 'list'
}
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rule-card',
  templateUrl: './rule-card.component.html',
  styleUrls: ['./rule-card.component.scss']
})
export class RuleCardComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() text: string;
  public direction: number = 0;
  public type: string = 'out';
  public directions = ['top', 'right', 'bottom', 'left'];

  constructor() {}

  public getDirection(ev, obj) {
    if (ev.type === 'mouseenter') this.type = 'in';
    else this.type = 'out';
    var w = obj.offsetWidth,
      h = obj.offsetHeight,
      x = (ev.clientX - obj.getBoundingClientRect().left - (w / 2) * (w > h ? (h / w) : 1)),
      y = (ev.clientY - obj.getBoundingClientRect().top - (h / 2) * (h > w ? (w / h) : 1)),
      d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;
      
    this.direction = d;
  };
}
import { Injectable } from '@angular/core';

import { TEXT } from '@assets/text';

@Injectable({
  providedIn: 'root'
})
export class TextService {
    public getText(key: string) {
        if (key) {
            return TEXT[key] || key;
        }
    }
}
import { Component, ViewEncapsulation } from '@angular/core';

import { WindowService } from '@shared/services/window.service';
import { RedirectService } from '@shared/services/redirect.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class FooterComponent {
  private readonly JRN_URL = "https://jrn-vision.com/";
  private readonly FACEBOOOK_URL = "https://www.facebook.com/concursul.biblictimotei.9";
  private readonly GMAIL_URL = "https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=concursultimotei@gmail.com#";

  constructor(private redirectService: RedirectService,
    private windowService: WindowService) { }

  redirectToAdmin() {
    this.redirectService.redirectToAdminPage();
  }

  redirectToJRN() {
    this.windowService.navigateTo(this.JRN_URL, true);
  }

  redirectToFacebook() {
    this.windowService.navigateTo(this.FACEBOOOK_URL, true);
  }

  redirectToGmail() {
    this.windowService.navigateTo(this.GMAIL_URL, true);
  }
}
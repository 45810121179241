import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TransformTextPipe } from '@shared/pipes/transform-text.pipe';
import { FormatInnerHtmlPipe } from '@shared/pipes/format-innerHtml.pipe';
import { FormatQuizAnswer } from '@shared/pipes/format-quiz-answer.pipe';
import { SplitStringPipe } from '@shared/pipes/split-string.pipe';
import { SafePipe } from '@shared/pipes/safe.pipe';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { BookLoaderComponent } from '@shared/components/book-loader/book-loader.component';
import { FloatingShapesComponent } from '@shared/components/floating-shapes/floating-shapes.component';
import { RotatingButtonComponent } from '@shared/components/forms/rotating-button/rotating-button.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { EllipsisDirective } from '@shared/directives/ellipsis.directive';

const SHARED_DECLARATIONS = [
    TransformTextPipe,
    FormatInnerHtmlPipe,
    SplitStringPipe,
    SafePipe,
    FormatQuizAnswer,
    LoaderComponent,
    BookLoaderComponent,
    FloatingShapesComponent,
    RotatingButtonComponent,
    IconComponent,
    EllipsisDirective
];

const SHARED_IMPORTS = [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatTooltipModule,
    MatGridListModule,
    MatRadioModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatDialogModule,
    MatPaginatorModule,
    MatProgressSpinnerModule
];

const SHARED_PROVIDERS = [
    MatPaginatorIntl
];

@NgModule({
  declarations: [
    ...SHARED_DECLARATIONS,
  ],
  imports: [
    ...SHARED_IMPORTS
  ],
  exports: [
    ...SHARED_DECLARATIONS,
    ...SHARED_IMPORTS
  ],
  providers: [
    ...SHARED_PROVIDERS
  ]
})
export class SharedModule { }
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { slideIn } from "@shared/animations/slide-in.animation";
import { signUpSlideText } from "@shared/animations/signUp-slide-text.animation";
import { ResponseState, SignUpInfo } from "@shared/models/shared.model";
import { ApiService } from "@shared/services/api.service";
import { WindowService } from "@shared/services/window.service";
import { GoogleAnalyticsService } from "@shared/services/google-analytics.service";
import { SettingsService } from '@app/shared/services/settings.service';
import { ToastService } from '@app/shared/services/toast.service';

@Component({
  selector: 'signup-section',
  templateUrl: './signup-section.component.html',
  styleUrls: ['./signup-section.component.scss'],
  animations: [slideIn, signUpSlideText],
  encapsulation: ViewEncapsulation.None
})
export class SignupSectionComponent implements OnInit {
  signupForm: FormGroup;
  classes = Array(12).fill(0).map((element, index) => index + 1);
  slideAnimation: Boolean = false;
  submittedForm: Boolean = false;
  state: ResponseState = undefined;
  isSignupPeriod: boolean = false;
  success: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private apiService: ApiService,
    private windowService: WindowService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private readonly settingsService: SettingsService,
    private readonly toastService: ToastService) { }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      class: ['', Validators.required],
      church: ['', Validators.required],
    });
    this.settingsService.settings$.subscribe(settings => {
      if (settings) {
        this.isSignupPeriod = settings['Inscrieri'] === 'Activ';
      }
    })
  }

  get controls() { return this.signupForm.controls; }

  public toggleSlideAnimation() {
    this.success = false;
    if (this.slideAnimation && !this.isFormClear())
      this.submitForm();
    else {
      if (!this.slideAnimation) {
        this.submittedForm = false;
        this.clearForm();
      }

      this.slideAnimation = !this.slideAnimation;
      if (this.slideAnimation && !this.isSignupPeriod)
        setTimeout(() => this.slideAnimation = !this.slideAnimation, 5000);
    }
  }

  public getTitleFontSizes() {
    if (this.windowService.isDesktopDevice())
      return { bigFontSize: 115, smallFontSize: 36 };
    if (this.windowService.isTabletDevice())
      return { bigFontSize: 66, smallFontSize: 36 };
    return { bigFontSize: 38, smallFontSize: 36, marginTop: 420 };
  }

  public submitForm() {
    this.submittedForm = true;
    if (this.signupForm.invalid)
      return;

    this.submittedForm = false;
    this.state = ResponseState.LOADING;
    const signUpInfo: SignUpInfo = this.createSignUpInfo();
    this.googleAnalyticsService.analyticsEventEmitter("contest_sign_up", "sign_up", signUpInfo.email, 1);
    this.apiService.sendSignUpDetails(signUpInfo).subscribe(
      _ => {
        this.startResponseAnimation(ResponseState.SUCCESS);
      },
      _ => {
        this.startResponseAnimation(ResponseState.ERROR);
    });
  }

  private createSignUpInfo(): SignUpInfo {
    var signUpInfo: SignUpInfo = new SignUpInfo();

    signUpInfo.nume = this.controls.firstName.value;
    signUpInfo.prenume = this.controls.lastName.value;
    signUpInfo.email = this.controls.email.value;
    signUpInfo.clasa = this.controls.class.value;
    signUpInfo.biserica = this.controls.church.value;

    return signUpInfo;
  }

  private startResponseAnimation(response: ResponseState) {
    setTimeout(() => {
      this.state = response;
      if (response === 2) {
        this.success = true;
        this.toastService.openToast('success', 'Success_Message_Sign_Up');
      }
      setTimeout(() => {
        this.state = undefined;
        setTimeout(() => this.slideAnimation = false, 500);
      }, 2000);
    }, 1000);
  }

  private isFormClear(): Boolean {
    return Object.values(this.signupForm.value).every(controlValue => !controlValue);
  }

  private clearForm() {
    Object.values(this.signupForm.controls).forEach(control => control.setValue(""));
  }
}
import { ContentChild, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
    selector: '[ellipsis]'
})
export class EllipsisDirective implements OnInit {
    @Input() ellipsisTooltip: MatTooltip;
    @Input() lineClamp: number = 1;
    @Input() maxWidth: string = '100%';

    private readonly ellipsisStyle = {
        'text-overflow': 'ellipsis',
        'overflow': 'hidden',
        'white-space': 'nowrap'
    };
    private nativeElement: any;

    constructor(private element: ElementRef) {}

    ngOnInit() {
        this.nativeElement = this.element.nativeElement;
        this.setEllipsisStyle();

        if (this.ellipsisTooltip) {
            this.ellipsisTooltip.disabled = true;
        }
    }

    @HostListener('mouseover')
    openToolTip() {
        if (this.ellipsisTooltip &&
            this.nativeElement.offsetWidth < this.nativeElement.scrollWidth) {
            this.ellipsisTooltip.disabled = false;
        }
    }

    @HostListener('mouseout')
    closeToolTip() {
        if (this.ellipsisTooltip) {
            this.ellipsisTooltip.disabled = true;
        }
    }

    private setEllipsisStyle() {
        this.ellipsisStyle['max-width'] = this.maxWidth;
        if (this.lineClamp > 1 && window.CSS.supports(`-webkit-line-clamp: ${this.lineClamp}`)) {
            this.ellipsisStyle['white-space'] = 'initial';
            this.ellipsisStyle['display'] = '-webkit-box';
            this.ellipsisStyle['-webkit-box-orient'] = 'vertical';
            this.ellipsisStyle['-webkit-line-clamp'] = this.lineClamp;
        }
        Object.keys(this.ellipsisStyle).forEach(property => {
            this.nativeElement.style[property] = this.ellipsisStyle[property];
        });
    }
}
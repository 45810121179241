import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';

import { ScrollService } from '@shared/services/scroll.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  showScrollToTop: boolean;
  enableScrollToTopPosition: number = 150;

  constructor(private scrollService: ScrollService,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.removeScrollbarStyleNode();
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.enableScrollToTopPosition) {
      this.showScrollToTop = true;
    } else {
      this.showScrollToTop = false;
    }
  }

  public scrollToTop () {
    this.scrollService.scrollToTop();
  }

  private removeScrollbarStyleNode() {
    let scrollBarStyleNode = this.document.head.getElementsByClassName("scrollBarStyle");

    if(scrollBarStyleNode.length)
      this.document.head.removeChild(scrollBarStyleNode[0]);
  }
}
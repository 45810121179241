import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector   : 'icon',
  templateUrl: './icon.component.html',
  styleUrls  : ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconComponent implements OnChanges {
  @Input('src')
  src: string;

  @Input('width')
  width = 14;

  @Input('height')
  height = 14;

  url: string = '';

  ngOnChanges() {
    this.url = 'assets/icons/' + this.src + '.svg';
  }
}

export const TEXT = {
    "Contact_Section_Title": "Contactează-ne",
    "Contact_Section_Details1": "Pentru mai multe detalii ne puteți contacta la:",
    "Contact_Section_Details2": "(0748) 951 120 - Paul Harasemiuc",
    "Contact_Error_LastName_Required": "Câmpul Nume este obligatoriu",
    "Contact_Error_FirstName_Required": "Câmpul Prenume este obligatoriu",
    "Contact_Error_Email_Required": "Câmpul Email este obligatoriu",
    "Contact_Error_Email_Invalid": "Adresa de email nu este validă",
    "Contact_Error_Message_Required": "Câmpul Mesaj este obligatoriu",
    "Error_Message_TryAgain": "Ceva nu a functionat corect. Incercati mai tarziu.",
    'Error_Message': 'Ups! Eroare! Asta nu trebuia să se întâmple',
    'Success_Message_Sign_Up': 'Te-ai înscris cu succes!',
    'Footer_Admin': 'Admin',
    'Landing_Title_Left': 'Concursul',
    'Landing_Title_Right': 'Biblic',
    'Results_Solutions': 'Barem',
    'Results_Group_Small': 'Grupa Mică',
    'Results_Group_Medium': 'Grupa Mijlocie',
    'Results_Group_High': 'Grupa Mare',
    'Rules_Groups_Title': 'Grupe',
    'Rules_Groups_Text':
        '<div bullet-space-section>Grupa mică: clasele I - IV</div>' +
        '<div bullet-space-section>Grupa mijlocie: clasele V - VIII</div>' +
        '<div bullet-section>Grupa mare: clasele IX - XII</div>',
    'Rules_Stages_Title': 'Detalii',
    'Rules_Stages_Text': 
        '<div bullet-space-section>Data:&nbsp; <span class="rules-date"></span></div>' +
        '<div bullet-space-section>Ora:&nbsp; <span class="rules-hour"></span></div>' +
        '<div bullet-space-section>Locația:&nbsp; <span class="rules-location"></span></div>',
    'Rules_SignUp_Title': 'Înscriere',
    'Rules_SignUp_Text': '<div bullet-space-section>Perioada:&nbsp; <span class="rules-period"></span></div>' +
        '<div bullet-space-section>Unde: la secțiunea <span class="sign-up">Înscriere</span></div>' +
        '<div bullet-section>Cine: copiii și tinerii care aparțin unei biserici Creștine după Evanghelie din județul Suceava</div>',
    'Rules_Structure_Title': 'Structura',
    'Rules_Structure_Text': '<div bullet-space-section>Cărți:&nbsp; <span class="rules-books"></span></div>' +
        '<div simple-space-section>Total întrebări:&nbsp; <span class="rules-total-questions"></span></div>' +
        '<div bullet-section>Întrebări la prima vedere:&nbsp; <span class="rules-first-sight"></span></div>' +
        '<div bullet-section>Versete de memorat:&nbsp; <span class="rules-number-verses"></span></div>' +
        '<div bullet-section><span>Întrebări din cele existente la secțiunea de pregătire:&nbsp; <span class="rules-existing-questions"></span></span></div>',
    'Rules_Pointing_Title': 'Punctaj',
    'Rules_Pointing_Text': '<div simple-space-section>1 întrebare = 5 puncte</div>' +
        '<div simple-section>Timp de lucru = 120 minute</div>',
    'Rules_Rewards_Title': 'Premiere',
    'Rules_Rewards_Text': '<div bullet-section>Premiul I (270-300 puncte): 1.000 RON</div>' +
        '<div bullet-section>Premiul II (240-269,99 puncte): 800 RON</div>' +
        '<div bullet-section>Premiul III (200-239,99 puncte): 600 RON</div>' +
        '<div bullet-space-section>Premiul "Timotei Junior" (150-200 puncte, doar pentru clasa I): 200 RON</div>' +
        '<div small-text>În situații excepționale, comisia de organizare își rezervă dreptul de a stabili cine se califică pentru a primi premiile.</div>',
    "Navbar_Item_Home": "Acasă",
    "Navbar_Item_Results": "Rezultate",
    "Navbar_Item_SignUp": "Înscriere",
    "Navbar_Item_Rules": "Regulament",
    "Navbar_Item_Training": "Pregătire",
    "Navbar_Item_Gallery": "Galerie",
    "Navbar_Item_Contact": "Contact",
    "SignUp_Section_Title": "Înscrie-te",
    "SignUp_Section_Success": "Te-ai înscris cu succes!",
    "SignUp_Section_New_Signup": "O nouă înscriere",
    "SignUp_Section_No_Signup": '<div>Perioada de înscrieri încă nu a început.</div>' +
        '<div>Te așteptăm mai târziu!</div>',
    "SignUp_Error_LastName_Required": "Câmpul Nume este obligatoriu",
    "SignUp_Error_FirstName_Required": "Câmpul Prenume este obligatoriu",
    "SignUp_Error_Email_Required": "Câmpul Email este obligatoriu",
    "SignUp_Error_Email_Invalid": "Adresa de email nu este valida",
    "SignUp_Error_Class_Required": "Câmpul Clasa este obligatoriu",
    "SignUp_Error_Church_Required": "Câmpul Biserica este obligatoriu",
    "Training_Tab_Exercises": "Exerciții",
    "Training_Tab_Resources": "Resurse",
    "Training_Book_Presentation_Title": 'Prezentarea cărților',
    "Training_Book_Presentation_Subtitle": 'Aici puteți găsi un material video pentru câteva dintre cărțile propuse',
    "Training_Files_GridView": 'Grilă',
    "Training_Files_ListView": 'Listă',
    "Training_Files_Title": 'Fișiere',
    "Training_Files_Empty_Message": "Nu există fișiere pentru categoriile selectate",
    "Training_Questions_List_Title": 'Listă Întrebări',
    "Training_Questions_Test_Title": 'Chestionar Biblic',
    "Training_Questions_Test_Subtitle": 'Un chestionar Biblic este alcătuit din 10 întrebări selectate în mod aleatoriu din cele propuse pentru concurs',
    "Training_Questions_Test_Button": 'Începe Chestionarul',
    "Training_Questions_No_Verses": 'Nu există chestionare pentru categoriile selectate',
    "Training_Crosswords_Test_Title": 'Rebus',
    "Training_Crosswords_Test_Subtitle": 'Aici puteți găsi un rebus cu întrebări ce vă va ajuta să învățați cât mai mult',
    "Training_Crosswords_Test_Button": 'Începe Rebusul',
    "Training_Crosswords_No_Verses": 'Nu există rebusuri pentru categoriile selectate',
    "Training_Verses_List_Title": 'Listă Versete',
    "Training_Verses_Test_Title": 'Versete Biblice',
    "Training_Verses_Test_Subtitle": 'Va fi nevoie să scrieți versete din memorie cu o precizie cât mai mare',
    "Training_Verses_Test_Button": 'Exerciții Versete',
    "Training_Verses_No_Verses": 'Nu există versete pentru categoriile selectate',
    "Training_Back_Button": 'Acasă',
    "Training_Section_Button": 'Pregătește-te',
    "Training_Finish_Quiz": "Finalizează",
    "Training_Excercise_Return": "Înapoi",
    "Training_Excercise_Cancel": "Anuleaza",
    "Training_Verse_Reference": "Referința versetului:",
    "Training_Verse_Placeholder": "Textul versetului...",
    "Question": "Întrebare",
    "Book": "Carte",
    "Group": "Grupa",
    "VerseReference": "Referință verset",
    "No_Questions": "Nicio întrebare disponibilă",
    "No_Verses": "Niciun verset disponibil",
    "Training_Dialog_Warning": 'Dacă părăsești pagina curentă, tot conținutul acesteia se va pierde. Esti sigur?',
    "Training_Dialog_Correct": 'Corect: ',
    "Training_Dialog_Yes": "Da",
    "Training_Dialog_No": "Nu",
    "Training_Verse_InputVerse": "Versetul completat:",
    "Training_Verse_OriginalVerse": "Versetul original: ",
    "Results_Table_Title": "Rezultate Finale",
    "Results_Table_GrupaMica": "Grupa Mică",
    "Results_Table_GrupaMijlocie": "Grupa Mijlocie",
    "Results_Table_GrupaMare": "Grupa Mare",
    "Results_Table_Contest_Year": "2023"
};
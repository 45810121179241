import { Injectable } from '@angular/core';
import { fileTypes } from '../utils/constants';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    openFile(file, fileName: string) {
        const extension = fileName.split('.').pop();
        if (fileTypes[extension]) {
          const blob = new Blob([file], { type: fileTypes[extension]});
          const fileURL = window.URL.createObjectURL(blob);
          if (extension === 'pdf') {
            setTimeout(() => window.open(fileURL, '_blank'), 0);
          } else {
            this.downloadFile(fileName, fileURL);
          }
        }
    }

    private downloadFile(fileName: string, fileUrl: string) {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.download = fileName;
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
}

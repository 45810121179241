<div class="signup-section-container">
    <div class="animated-container">
        <div *ngIf="!isSignupPeriod" [@slideIn]="slideAnimation" class="no-signup">
            <div [innerHtml]="'SignUp_Section_No_Signup' | transformText | formatInnerHtml"></div>
        </div>
        <form *ngIf="isSignupPeriod" [@slideIn]="slideAnimation" #form="ngForm" class="signupForm"
            [formGroup]="signupForm" (ngSubmit)="submitForm()">
            <div>
                <div class="flex-row">
                    <div class="form-group lastName">
                        <div class="icon-input-container">
                            <div class="input-icon">
                                <icon class="icon" src="Form_LastName" [width]="20" [height]="20"></icon>
                            </div>
                            <input type="text" formControlName="lastName" class="form-control" placeholder="Nume"
                                [ngClass]="{ 'invalid-input': submittedForm && controls.lastName.errors }"
                                matTooltip="{{ 'SignUp_Error_LastName_Required' | transformText }}"
                                matTooltipPosition="left"
                                [matTooltipDisabled]="!submittedForm || !controls.lastName.errors" />
                        </div>
                    </div>
                    <div class="form-group firstName">
                        <div class="icon-input-container">
                            <div class="input-icon">
                                <icon class="icon" src="Form_FirstName" [width]="20" [height]="20"></icon>
                            </div>
                            <input type="text" formControlName="firstName" class="form-control" placeholder="Prenume"
                                [ngClass]="{ 'invalid-input': submittedForm && controls.firstName.errors }"
                                matTooltip="{{ 'SignUp_Error_FirstName_Required' | transformText }}"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!submittedForm || !controls.firstName.errors" />
                        </div>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="form-group email">
                        <div class="icon-input-container">
                            <div class="input-icon">
                                <icon class="icon" src="Form_Envelope" [width]="20" [height]="20"></icon>
                            </div>
                            <input type="text" formControlName="email" class="form-control" placeholder="Email"
                                [ngClass]="{ 'invalid-input': submittedForm && controls.email.errors }"
                                matTooltip="{{ (controls.email.errors?.required ? 'SignUp_Error_Email_Required' : 'SignUp_Error_Email_Invalid') | transformText }}"
                                matTooltipPosition="left"
                                [matTooltipDisabled]="!submittedForm || !controls.email.errors" />
                        </div>
                    </div>
                    <div class="form-group class">
                        <div class="icon-input-container">
                            <div class="input-icon">
                                <icon class="icon" src="Form_GraduationCap" [width]="20" [height]="20"></icon>
                            </div>
                            <select formControlName="class" class="form-control"
                                [ngClass]="{ 'invalid-input': submittedForm && controls.class.errors, 'placeholder-option': !controls.class.value }"
                                matTooltip="{{ 'SignUp_Error_Class_Required' | transformText }}"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!submittedForm || !controls.class.errors">
                                <option value="" disabled selected>Clasa</option>
                                <option *ngFor="let class of classes" [ngValue]="class">{{class}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group flex-row church">
                    <div class="icon-input-container">
                        <div class="input-icon">
                            <icon class="icon" src="Form_Church" [width]="20" [height]="20"></icon>
                        </div>
                        <input type="text" formControlName="church" class="form-control" placeholder="Biserica"
                            [ngClass]="{ 'invalid-input': submittedForm && controls.church.errors }"
                            matTooltip="{{ 'SignUp_Error_Church_Required' | transformText }}"
                            matTooltipPosition="left"
                            [matTooltipDisabled]="!submittedForm || !controls.church.errors" />
                    </div>
                </div>
            </div>
        </form>
        <div *ngIf="success" class="success-message">{{'SignUp_Section_Success' | transformText}}</div>
        <div class="animated-button"
            [ngClass]="{'animated-border': !slideAnimation, 'submit-button':slideAnimation && isSignupPeriod, 'hide-button':(slideAnimation && !isSignupPeriod) || (state && isSignupPeriod), 'show-button':(!slideAnimation && !isSignupPeriod) || (!state && isSignupPeriod)}"
            (click)="toggleSlideAnimation()"
            [@signUpSlideText]="{value: slideAnimation && isSignupPeriod, params: getTitleFontSizes()}">
            <div *ngIf="!success" class="animated-button-text">{{'SignUp_Section_Title' | transformText}}</div>
            <div *ngIf="success" class="animated-button-text">{{'SignUp_Section_New_Signup' | transformText}}</div>
        </div>
        <loader class="loader" *ngIf="state" [state]="state" [errorMessage]="'Error_Message_TryAgain' | transformText"></loader>
    </div>
</div>
import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  public analyticsEventEmitter(eventAction: string, eventCategory: string, eventLabel: string = null,  eventValue: number = null ){ 
      gtag('event', eventAction, { 
        eventCategory: eventCategory, 
        eventLabel: eventLabel, 
        eventValue: eventValue
      })
    }
}
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

const styleMap = [
    { key: 'bullet-section>', value: 'style="display:flex;"><li></li>' },
    { key: 'simple-section', value: 'style="text-indent:18px"' },
    { key: 'bullet-space-section>', value: 'style="display:flex;margin-bottom:10px"><li></li>' },
    { key: 'simple-space-section', value: 'style="text-indent:18px;margin-bottom:10px"' },
    { key: 'small-text', value: 'style="font-size:12px"' },
]

@Pipe({ name: 'formatInnerHtml' })
export class FormatInnerHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: string): any {
        if (value) {
            let newValue = value;
            for (let style of styleMap) {
                newValue = newValue.split(style.key).join(style.value);
            }
            return this.sanitizer.bypassSecurityTrustHtml(newValue) || '';
        }
    }
}
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { WindowService } from '@shared/services/window.service';
import { ScrollService } from '@shared/services/scroll.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isMobileDevice: boolean;
  detachNavbar: boolean;
  detachNavbarPosition: number = 10;
  toggleHamburger: boolean = false;
  color: string = "body";

  constructor(private windowService: WindowService,
              private scrollService: ScrollService,
              private router: Router) { }

  ngOnInit() {
    this.isMobileDevice = this.windowService.isMobileDevice();
  }

  @HostListener('window:scroll')
  checkScroll() {
    if (this.hasWindowScroll())
      this.detachNavbar = true;
    else
      this.detachNavbar = false;
  }

  public scrollTo(section: string) {
    this.scrollService.scrollToElementID(section);
  }

  public scrollToTop() {
    if (this.toggleHamburger) {
      this.toggleHamburger = false;
      setTimeout(() => this.scrollToTop(), 500);
    }
    else
      this.scrollService.scrollToTop();
  }

  public redirectToTrainingPage() {
    this.router.navigate(['/pregatire']);
  }

  public toggleSideNavbar() {
    if (this.hasWindowScroll())
      this.color = "white";
    else
      this.color = "body";

    this.toggleHamburger = !this.toggleHamburger;
  }

  public closeMobileNavbarAndRedirect(sectionID: string) {
    switch (sectionID) {
      case "training":
        this.redirectToTrainingPage();
        break;
      default:
        this.toggleHamburger = false;
        setTimeout(() => this.scrollTo(sectionID), 500);
        break;
    }
  }

  private hasWindowScroll(): boolean {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition > this.detachNavbarPosition)
      return true;
    return false;
  }
}
<div class="navbar" id="navbar">
    <div class="navbar-container" [ngClass]="{'detached-navbar': detachNavbar}">
        <img *ngIf="detachNavbar" class="logo" src="../../../assets/images/logo-black.png" alt="navbar-logo" (click)="scrollToTop()" />
        <div class="navbar-item-container" [ngClass]="{'detached-navbar-items': detachNavbar}">
            <div class="navbar-item" (click)="scrollTo('results')">{{'Navbar_Item_Results' | transformText}}</div>
            <div class="navbar-item" (click)="scrollTo('signup')">{{'Navbar_Item_SignUp' | transformText}}</div>
            <div class="navbar-item" (click)="scrollTo('rules')">{{'Navbar_Item_Rules' | transformText}}</div>
            <div class="navbar-item" (click)="redirectToTrainingPage()">{{'Navbar_Item_Training' | transformText}}</div>
            <div class="navbar-item" (click)="scrollTo('gallery')">{{'Navbar_Item_Gallery' | transformText}}</div>
            <div class="navbar-item" (click)="scrollTo('footer')">{{'Navbar_Item_Contact' | transformText}}</div>
        </div>
        <div class="navbar-mobile" (click)="toggleSideNavbar()">
            <div class="hamburger-menu" [ngClass]="{'animate': toggleHamburger}"></div>	  
        </div>
    </div>
    <mobile-side-navbar (closeMobileNavbarAndRedirect)="closeMobileNavbarAndRedirect($event)" 
                        class="mobile-side-navbar" 
                        [openSideNavbar]="toggleHamburger" 
                        [color]="color">
    </mobile-side-navbar>
</div>
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class ResultsGroupGuard implements CanActivate {
  constructor(private router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot): boolean {
    let group = route.paramMap.get('group');

    if(group !== "1" && group !== "2" && group !== "3") {
        this.router.navigate(['']);
        return false;
    }
    
    return true;
  }
}
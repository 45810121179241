import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatQuizAnswer' })
export class FormatQuizAnswer implements PipeTransform {
    public invalidEndingCharacters = [',','.',';',':'];

    transform(value: string): string {
        if (value && this.invalidEndingCharacters.indexOf(value[value.length -1]) !== -1) {
            return value.slice(0, value.length - 1);       
        } 
        return value;
    }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private settings = {};
  private settingsSubject: BehaviorSubject<Object> = new BehaviorSubject(null);
  public readonly settings$: Observable<Object> = this.settingsSubject.asObservable();


  constructor() {}

  addSetting(key: string, value: string) {
    this.settings[key] = value;
    this.settingsSubject.next(this.settings);
  }

  addSettings(settings: any) {
    this.settings = settings;
    this.settingsSubject.next(this.settings);
  }
}

import { Component } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import * as AOS from 'aos';
import { ApiService } from "./shared/services/api.service";
import { SettingsService } from "./shared/services/settings.service";

declare var gtag;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  constructor(router: Router, 
    private readonly apiService: ApiService,
    private readonly settingsService: SettingsService) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('config', 'G-RD7368F96G', {
        'page_path': event.urlAfterRedirects
      });
    });
  }

  ngOnInit() {
    AOS.init({ duration: 800, once: true });
    window.onunload = function () { window.scrollTo(0, 0); }
    this.apiService.getSettings().subscribe(res => 
      {
        let settings = {};
        res.forEach(setting => settings[setting.Key] = setting.Value);
        this.settingsService.addSettings(settings);
      }
    );
  }
}
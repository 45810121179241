import { Component } from '@angular/core';
import { ScrollService } from '@app/shared/services/scroll.service';
import { SettingsService } from '@app/shared/services/settings.service';

@Component({
  selector: 'rules-section',
  templateUrl: './rules-section.component.html',
  styleUrls: ['./rules-section.component.scss']
})
export class RulesSectionComponent {
  public cards = [{
      icon: 'Rules_Groups_Icon',
      title: 'Rules_Groups_Title',
      text: 'Rules_Groups_Text'
    },
    {
      icon: 'Rules_Stages_Icon',
      title: 'Rules_Stages_Title',
      text: 'Rules_Stages_Text'
    },
    {
      icon: 'Rules_SignUp_Icon',
      title: 'Rules_SignUp_Title',
      text: 'Rules_SignUp_Text'
    },
    {
      icon: 'Rules_Structure_Icon',
      title: 'Rules_Structure_Title',
      text: 'Rules_Structure_Text'
    },
    {
      icon: 'Rules_Pointing_Icon',
      title: 'Rules_Pointing_Title',
      text: 'Rules_Pointing_Text'
    },
    {
      icon: 'Rules_Rewards_Icon',
      title: 'Rules_Rewards_Title',
      text: 'Rules_Rewards_Text'
    }
  ];

  settings = {};

  constructor(
    private scrollService: ScrollService, 
    private readonly settingsService: SettingsService
    ) 
  {}
  
  ngOnInit() {
    setTimeout(() => {
      let element = document.getElementsByClassName('sign-up')[0];
      if (element) {
        element.addEventListener('click', _ => this.scrollToSignUp());
      }
      this.settingsService.settings$.subscribe(settings => {
        this.settings = settings;
        this.setDynamicValues();
      });
    }, 0);
  }

  private scrollToSignUp() {
    this.scrollService.scrollToElementID('signup');
  }

  private setDynamicValues() {
    if (!this.settings) {
      return;
    }

    let element = document.getElementsByClassName('rules-date')[0];
    if (element) {
      element.innerHTML = this.settings['Regulament_Data'];
    }

    element = document.getElementsByClassName('rules-hour')[0];
    if (element) {
      element.innerHTML = this.settings['Regulament_Ora'];
    }

    element = document.getElementsByClassName('rules-location')[0];
    if (element) {
      element.innerHTML = this.settings['Regulament_Locatie'];
    }

    element = document.getElementsByClassName('rules-period')[0];
    if (element) {
      element.innerHTML = this.settings['Regulament_Perioada_Inscriere'];
    }

    element = document.getElementsByClassName('rules-books')[0];
    if (element) {
      element.innerHTML = this.settings['Regulament_Carti'];
    }

    element = document.getElementsByClassName('rules-total-questions')[0];
    if (element) {
      element.innerHTML = this.settings['Regulament_Total_Intrebari'];
    }

    element = document.getElementsByClassName('rules-first-sight')[0];
    if (element) {
      element.innerHTML = this.settings['Regulament_Intrebari_Prima_Vedere'];
    }

    element = document.getElementsByClassName('rules-number-verses')[0];
    if (element) {
      element.innerHTML = this.settings['Regulament_Versete_Memorat'];
    }

    element = document.getElementsByClassName('rules-existing-questions')[0];
    if (element) {
      element.innerHTML = this.settings['Regulament_Intrebari_Existente'];
    }
  }
}
import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import { environment } from 'environments/environment';

import { Angular2ImageGalleryModule } from "angular2-image-gallery";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { ToastrModule } from "ngx-toastr";

import { SharedModule } from '@shared/shared.module';
import { ResultsGroupGuard } from '@shared/guards/results-group.guard';

import { HomeComponent } from '@components/home/home.component';
import { LandingSectionComponent } from "@components/home/landing-section/landing-section.component";
import { NavbarComponent } from '@components/home/navbar/navbar.component';
import { RulesSectionComponent } from '@components/home/rules-section/rules-section.component';
import { RuleCardComponent } from '@components/home/rules-section/rule-card/rule-card.component';
import { ResultCardComponent } from '@components/home/results-section/result-card/result-card.component';
import { ResultsSectionComponent } from '@components/home/results-section/results-section.component';
import { SignupSectionComponent } from '@components/home/signup-section/signup-section.component';
import { FooterComponent } from '@components/home/footer/footer.component';
import { ContactSectionComponent } from '@components/home/contact-section/contact-section.component';
import { MobileSideNavbarComponent } from '@components/home/navbar/mobile-side-navbar/mobile-side-navbar.component';
import { TrainingSectionComponent } from '@components/home/training-section/training-section.component';
import { ResultsTableComponent } from '@components/home/results-section/results-table/results-table.component';
import { GridGalleryComponent } from '@components/home/grid-gallery/grid-gallery.component';

const HOME_DECLARATIONS = [
  HomeComponent,
  LandingSectionComponent,
  NavbarComponent,
  RulesSectionComponent,
  RuleCardComponent,
  ResultCardComponent,
  ResultsSectionComponent,
  SignupSectionComponent,
  FooterComponent,
  ContactSectionComponent,  
  MobileSideNavbarComponent,
  TrainingSectionComponent,
  ResultsTableComponent,
  GridGalleryComponent
];

@NgModule({
  declarations: [
    AppComponent,
    ...HOME_DECLARATIONS
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    SharedModule,
    Angular2ImageGalleryModule,
    RecaptchaV3Module,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 5000,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right',
    }),
  ],
  providers: [
    ResultsGroupGuard,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LcK8CcbAAAAAAAvr61uWTcgfoz5gIH5Gwe7zqBo" }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
<div class="page-container">
    <div class="back-button" (click)="returnToHomePage()">
        <icon class="icon" src="Training_Back_Icon" [width]="42" [height]="42"></icon>
        <div class="text">{{'Training_Back_Button' | transformText}}</div>
    </div>
    <div class="title-section">
        <div class="title">{{'Results_Table_Title' | transformText}}</div>
        <div *ngIf="group" class="group" [ngSwitch]="group">
            <div *ngSwitchCase="1">{{'Results_Table_GrupaMica' | transformText}}</div>
            <div *ngSwitchCase="2">{{'Results_Table_GrupaMijlocie' | transformText}}</div>
            <div *ngSwitchCase="3">{{'Results_Table_GrupaMare' | transformText}}</div>
        </div>
         <div class="contest-year">{{settings[settingsMap['Results_Table_Contest_Year']] || ('Results_Table_Contest_Year' | transformText)}}</div>
    </div>
    <div class="table-container">
        <book-loader *ngIf="!tableRows || !tableRows.length"></book-loader>
        <table *ngIf="tableRows && tableRows.length" mat-table [dataSource]="tableRows" class="results-table mat-elevation-z8">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of header; let columnIndex = index;">
                <th mat-header-cell *matHeaderCellDef>{{column}}</th>
                <td mat-cell class="element-row" *matCellDef="let row;">{{row[columnIndex] || '--'}} </td> 
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="header; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: header"></tr>
        </table>
    </div>
</div>   
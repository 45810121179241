import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/shared/services/api.service';
import { FileService } from '@app/shared/services/file.service';

@Component({
  selector: 'result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss']
})
export class ResultCardComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() filePath: string;

  size: number;
  group: string = '';

  constructor(private router: Router, 
              private apiService: ApiService,
              private fileService: FileService) { }

  ngOnInit() {
    switch(this.filePath) {
      case "1": 
        this.size = 26;
        this.group = "Mica";
        break;
      case "2": 
        this.size = 33;
        this.group = "Mijlocie";
        break;
      case "3": 
        this.size = 40;
        this.group = "Mare";
        break;
    }
  }

  redirectToResultsPage() { 
    this.router.navigate(['/rezultate', this.filePath]);
  }

  openAnswersFile() {
    this.apiService.getFile(this.group).subscribe(result => this.fileService.openFile(result,this.group+'.pdf'));
  }
}
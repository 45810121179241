<div class="card-container">
    <mat-card (mouseenter)="getDirection($event, $event.target)" (mouseleave)="getDirection($event, $event.target)">
        <mat-card-header>
            <div mat-card-avatar>
                <icon [src]="icon" [width]="40" [height]="40"></icon>
            </div>
            <mat-card-title>{{title | transformText}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div [innerHtml]="text | transformText | formatInnerHtml"></div>
        </mat-card-content>
        <div [ngClass]="['overlay', directions[direction], type]"></div>
    </mat-card>
</div>
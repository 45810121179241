import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";

import { Cacheable } from "ngx-cacheable/dist/cjs/cacheable.decorator";

import { ContactInfo, Setting, SignUpInfo } from '@shared/models/shared.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  protected baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseURL;
  }

  @Cacheable()
  getVersesResources(number: string = "1000") {
    let versesUrl =
      this.baseUrl + `/versete?numar=${number}&grupa=Toate&carte=Toate`;
    return this.http.get(versesUrl);
  }

  getVerses(
    number: string = "3",
    group: string = "Toate",
    book: string = "Toate"
  ) {
    let versesUrl =
      this.baseUrl + `/versete?numar=${number}&grupa=${group}&carte=${book}`;
    return this.http.get(versesUrl);
  }

  @Cacheable()
  getQuestionsResources(number: string = "0") {
    let questionsUrl =
      this.baseUrl + `/intrebarigrila?numar=${number}&grupa=Toate&carte=Toate`;
    return this.http.get(questionsUrl);
  }

  getQuestions(
    number: string = "10",
    group: string = "Toate",
    book: string = "Toate"
  ) {
    let questionsUrl =
      this.baseUrl +
      `/intrebarigrila?numar=${number}&grupa=${group}&carte=${book}`;
    return this.http.get(questionsUrl);
  }

  getCrosswordConfigurations(
    number: string = "10",
    group: string = "Toate",
    book: string = "Toate"
  ) {
    let crosswordUrl =
      this.baseUrl + `/rebusuri?numar=${number}&grupa=${group}&carte=${book}`;
    return this.http.get(crosswordUrl);
  }

  sendContactDetails(contactInfo: ContactInfo) {
    return this.http.post(this.baseUrl + "/contact", contactInfo);
  }

  sendSignUpDetails(signUpInfo: SignUpInfo) {
    return this.http.post(this.baseUrl + "/inscriere", signUpInfo);
  }

  @Cacheable()
  getFileDetails(group: string = "Toate", book: string = "Toate") {
    let filesUrl = this.baseUrl + `/fisiere?grupa=${group}&carte=${book}`;
    return this.http.get(filesUrl);
  }

  @Cacheable()
  getFileContent(fileId: number) {
    return this.http.get(this.baseUrl + `/fisiere/${fileId}`, {
      responseType: "arraybuffer",
    });
  }

  @Cacheable()
  getSettings(): Observable<Setting[]> {
    return this.http.get<string>(this.baseUrl + `/setari`).pipe(map(settings => JSON.parse(settings)));
  }

  @Cacheable()
  getFile(fileName: string) {
    return this.http.get(this.baseUrl + `/fisiereadmin/${fileName}`, {responseType: "blob"});
  }
}

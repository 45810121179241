import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { sideMenuSlide } from '@shared/animations/side-menu-slide.animation';

@Component({
  selector: 'mobile-side-navbar',
  templateUrl: './mobile-side-navbar.component.html',
  styleUrls: ['./mobile-side-navbar.component.scss'],
  animations: [sideMenuSlide]
})
export class MobileSideNavbarComponent {
  _openSideNavbar: boolean = false;

  @Input() color: string;
  @Input() set openSideNavbar(value: boolean) {
      this._openSideNavbar = value;
      this.toggleOverflow();
  }

  @Output() closeMobileNavbarAndRedirect: EventEmitter<string> = new EventEmitter<string>();

  constructor(@Inject(DOCUMENT) private document: Document) { }

  public closeNavbarAndRedirect(sectionID: string) {
    this.closeMobileNavbarAndRedirect.emit(sectionID);
  }

  private toggleOverflow() {
    if (this._openSideNavbar) {
      this.document.documentElement.style.overflow = 'hidden';
    } else {
      this.document.documentElement.style.removeProperty('overflow');
    }
  }
}

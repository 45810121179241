<navbar></navbar>
<landing-section></landing-section>
<results-section id="results"></results-section>
<signup-section id="signup"></signup-section>
<rules-section id="rules"></rules-section>
<training-section id="training"></training-section>
<grid-gallery id="gallery"></grid-gallery>
<contact-section id="contact"></contact-section>
<footer id="footer"></footer>
<icon *ngIf="showScrollToTop" class="goToTopBtn" (click)="scrollToTop()"
src="Navbar_ScrollToTop_Arrow" [width]="20" [height]="20"></icon>
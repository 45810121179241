import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ReCaptchaV3Service } from 'ng-recaptcha';

import { slideIn } from '@shared/animations/slide-in.animation';
import { contactSlideText } from '@shared/animations/contact-slide-text.animation';
import { ScrollService } from '@shared/services/scroll.service';
import { ResponseState, ContactInfo } from '@shared/models/shared.model';
import { ApiService } from '@shared/services/api.service';
import { WindowService } from '@shared/services/window.service';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';

@Component({
  selector: 'contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss'],
  animations: [slideIn, contactSlideText]
})
export class ContactSectionComponent implements OnInit {
  contactForm: FormGroup;
  slideAnimation: Boolean = false;
  submittedForm: Boolean = false;
  state: ResponseState = undefined;

  constructor(private formBuilder: FormBuilder,
    private scrollService: ScrollService,
    private apiService: ApiService,
    private windowService: WindowService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
    });
  }

  get controls() { return this.contactForm.controls; }

  public toggleSlideAnimation() {
    if (this.slideAnimation && !this.isFormClear())
      this.submitForm();
    else {
      if (!this.slideAnimation) {
        this.submittedForm = false;
        this.clearForm();
        setTimeout(() => this.scrollService.scrollToElementID("footer"), 501);
      }

      this.slideAnimation = !this.slideAnimation;
    }
  }

  public getTitleFontSizes() {
    if (this.windowService.isDesktopDevice())
      return { bigFontSize: 115, smallFontSize: 36 };
    if (this.windowService.isTabletDevice())
      return { bigFontSize: 66, smallFontSize: 36 };
    return { bigFontSize: 38, smallFontSize: 27, marginTop: 450 };
  }

  public submitForm() {
    this.submittedForm = true;
    if (this.contactForm.invalid)
      return;

    this.submittedForm = false;
    this.state = ResponseState.LOADING;
    var contactInfo: ContactInfo = this.createContactInfo();
    this.googleAnalyticsService.analyticsEventEmitter("contact", "contact_form", contactInfo.email, 2);
    this.apiService.sendContactDetails(contactInfo).subscribe(
      _ => {
        this.startResponseAnimation(ResponseState.SUCCESS);
      },
      _ => {
        this.startResponseAnimation(ResponseState.ERROR);
      });
  }

  private startResponseAnimation(response: ResponseState) {
    setTimeout(() => {
      this.state = response;
      setTimeout(() => {
        this.state = undefined;
        setTimeout(() => this.slideAnimation = false, 500);
      }, 2000);
    }, 1000);
  }

  private createContactInfo(): ContactInfo {
    var contactInfo: ContactInfo = new ContactInfo();

    contactInfo.nume = this.controls.firstName.value;
    contactInfo.prenume = this.controls.lastName.value;
    contactInfo.email = this.controls.email.value;
    contactInfo.mesaj = this.controls.message.value;

    return contactInfo;
  }

  private isFormClear(): Boolean {
    return Object.values(this.contactForm.value).every(controlValue => !controlValue);
  }

  private clearForm() {
    Object.values(this.contactForm.controls).forEach(control => control.setValue(""));
  }
}
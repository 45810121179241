import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'training-section',
  templateUrl: './training-section.component.html',
  styleUrls: ['./training-section.component.scss']
})
export class TrainingSectionComponent {

  constructor(private router: Router) { }

  public goToTraining() {
    this.router.navigate(['/pregatire']);
  }
}

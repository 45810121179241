<div class="contact-section-container">
    <div class="animated-container">
        <form #form="ngForm" 
            [@slideIn]="slideAnimation"  
            class="contactForm" 
            [formGroup]="contactForm" 
            (ngSubmit)="submitForm()">
            <div>
                <div class="flex-row">
                    <div class="form-group lastName">
                        <div class="icon-input-container">
                            <div class="input-icon">
                                <icon class="icon" src="Form_LastName" [width]="20" [height]="20"></icon>
                            </div>
                            <input type="text" formControlName="lastName" class="form-control" placeholder="Nume"
                                [ngClass]="{ 'invalid-input': submittedForm && controls.lastName.errors }"
                                matTooltip="{{ 'Contact_Error_LastName_Required' | transformText }}"
                                matTooltipPosition="left"
                                [matTooltipDisabled]="!submittedForm || !controls.lastName.errors" />
                        </div>
                    </div>
                    <div class="form-group firstName">
                        <div class="icon-input-container">
                            <div class="input-icon">
                                <icon class="icon" src="Form_FirstName" [width]="20" [height]="20"></icon>
                            </div>
                            <input type="text" formControlName="firstName" class="form-control" placeholder="Prenume"
                                [ngClass]="{ 'invalid-input': submittedForm && controls.firstName.errors }"
                                matTooltip="{{ 'Contact_Error_FirstName_Required' | transformText }}"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!submittedForm || !controls.firstName.errors" />
                        </div>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="form-group email">
                        <div class="icon-input-container">
                            <div class="input-icon">
                                <icon class="icon" src="Form_Envelope" [width]="20" [height]="20"></icon>
                            </div>
                            <input type="text" formControlName="email" class="form-control" placeholder="Email"
                                [ngClass]="{ 'invalid-input': submittedForm && controls.email.errors }"
                                matTooltip="{{ (controls.email.errors?.required ? 'Contact_Error_Email_Required' : 'Contact_Error_Email_Invalid') | transformText }}"
                                matTooltipPosition="right"
                                [matTooltipDisabled]="!submittedForm || !controls.email.errors" />
                        </div>
                    </div>
                </div>
                <div class="form-group flex-row message">
                    <div class="icon-input-container">
                        <div class="input-icon">
                            <icon class="icon" src="Form_Message" [width]="20" [height]="20"></icon>
                        </div>
                        <textarea type="text" formControlName="message" class="form-control" placeholder="Mesaj"
                            [ngClass]="{ 'invalid-input': submittedForm && controls.message.errors }"
                            matTooltip="{{ 'Contact_Error_Message_Required' | transformText }}"
                            matTooltipPosition="right"
                            [matTooltipDisabled]="!submittedForm || !controls.message.errors">
                    </textarea>
                    </div>
                </div>
                <div class='details'>
                    <span>{{'Contact_Section_Details1' | transformText}}</span>
                    <span>{{'Contact_Section_Details2' | transformText}}</span>
                </div>
            </div>
        </form>
        <div *ngIf="!state" class="animated-button"
            [ngClass]="{'animated-border': !slideAnimation, 'submit-button':slideAnimation}"
            (click)="toggleSlideAnimation()" [@contactSlideText]="{value: slideAnimation, params: getTitleFontSizes()}">
            <div class="animated-button-text">{{'Contact_Section_Title' | transformText}}</div>
        </div>
        <loader class="loader" *ngIf="state" [state]="state" [errorMessage]="'Error_Message_TryAgain' | transformText"></loader>
    </div>
</div>
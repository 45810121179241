import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ResultsGroupGuard } from "@shared/guards/results-group.guard";

import { HomeComponent } from "@components/home/home.component";
import { ResultsTableComponent } from "@components/home/results-section/results-table/results-table.component";

const routes: Routes = [
  {
    path: "", 
    component: HomeComponent
  },
  { 
    path: "pregatire", 
    loadChildren: () => import('@components/training/training.module').then(m => m.TrainingModule)
  },
  {
    path: 'rezultate/:group', 
    component: ResultsTableComponent,
    canActivate: [ResultsGroupGuard] 
  },
  { 
    path: "**", 
    redirectTo: '' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component } from '@angular/core';

@Component({
  selector: 'results-section',
  templateUrl: './results-section.component.html',
  styleUrls: ['./results-section.component.scss']
})
export class ResultsSectionComponent{
  public cards = [{
      icon: 'Results_Group_Small_Icon',
      title: 'Results_Group_Small',
      filePath: '1'
    },
    {
      icon: 'Results_Group_Medium_Icon',
      title: 'Results_Group_Medium',
      filePath: '2'
    },
    {
      icon: 'Results_Group_High_Icon',
      title: 'Results_Group_High',
      filePath: '3'
    }
  ];
}